import styled from 'styled-components';
import variables, { mq, fs, screenSize } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding: ${props => props.styleType === 'pricing' ? '4vh' : '0px'} 5vw ${props => props.styleType === 'pricing' ? '20vh' : '50px'};

  ${mq('l')} {
    min-height: 'none';
    padding: 45px 35px ${props => props.styleType === 'pricing' ? '20vh' : '50px'};
  }

  ${mq('s')} {
    padding: 45px 20px 50px;
  }

  h2 {
    color: ${variables.colors.black};
    ${fs(36)};
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    margin-bottom: 45px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${mq('l')} {
    flex-direction: column;
  }


  .steps {
    width: calc(50% - 60px);

    ${mq('l')} {
      width: 100%;
    }
    
    
    ol {
      margin: 0;
      list-style-type: none;
      background: #FFFFFF;
      box-shadow: 0px 24px 54px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      ${screenSize('padding', 40)}

    }

    li {
      color: ${variables.colors.blue};
      ${fs(16)};
      font-family: 'Bossa', sans-serif;
      font-weight: 500;

      strong {
        color: ${variables.colors.black};
        ${fs(24)};
        font-weight: 500;
        ${screenSize('margin-left', 40)}

        ${mq('l')} {
          ${fs(18)};
        }
      }

      + li {
        ${screenSize('margin-top', 40)}
        ${screenSize('padding-top', 40)}
        border-top: 1px dotted ${variables.colors.greyLight}
      }
    }
  }

  .form-wrapper {
    max-width: calc(50% - 60px);
    width: 850px;
    margin: 0;

    ${mq('l')} {
      max-width: 100%;
      margin-top: 70px;
    }

    h3 {
      color: ${variables.colors.black};
      ${fs(24)};
      font-family: 'Bossa', sans-serif;
      font-weight: 500;
      margin-bottom: 10px;
    }
    
    .header {
      p {
        color: ${variables.colors.black};
        ${fs(18)};
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin-bottom: 45px;
        line-height: 1.7;
      }
    }

    .form-container {
      margin-top: 30px;
    }
    

    fieldset {
      max-width: 100%;

      > div {
        padding-left: 30px;
        padding-right: 30px;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }

        ${mq('m')} {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

  

    .hbspt-form {

      .field {
        ${screenSize('margin-bottom', 30)}

        label {
          display: block;
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          color: ${variables.colors.backgroundDark};

          ${fs(18)}
          ${screenSize('margin-bottom', 10)}
        }

        input[type="text"],
        input[type="email"],
        input[type="number"],
        textarea {
          width: 100%;
          border: 1px solid ${variables.colors.greyLight};
          border-radius: 8px;
          ${fs(16)}

          ${screenSize('height', 60)}
          ${screenSize('line-height', 60)}
          padding-left: 10px;
          padding-right: 10px;
        }

        textarea {
          min-height: 120px;
          ${fs(16)}
          padding-left: 10px;
          padding-right: 10px;
          line-height: 1.4em;
        }

        select {
          width: 100%;
          border: 1px solid ${variables.colors.greyLight};
          border-radius: 8px;
          padding-left: 10px;
          padding-right: 10px;

          ${screenSize('height', 40)}
          ${screenSize('line-height', 40)}
          ${fs(16)}
        }

        
      }

      .hs-richtext {
        p {
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          ${fs(14)};
        }
        a {
          color: ${variables.colors.red};
          text-decoration: none;
          font-weight: 700;

          &:hover {
            color: ${variables.colors.blue};
          }
        }
      }

      .hs-newsletter_community {
        .inputs-list {
          list-style-type: none;
          margin-left: 0;
        }

        .hs-form-booleancheckbox {
          input {
            position: absolute;
            left: -10000px;

            & + span {
              display: block;
              position: relative;
              padding-left: 35px;

              &::before {
                content: '';
                position: absolute;
                top: 5px;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 4px;
                border: 1px solid ${variables.colors.greyLight};
              }

              &::after {
                content: '';
                opacity: 0;
                position: absolute;
                top: 8px;
                left: 3px;
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 2px;
                border: 1px solid ${variables.colors.blue};
                background: ${variables.colors.blue};
                transition: opacity .3s ease-in-out;
              }

              
            }

            &:checked + span::after {
                opacity: 1;
              }
          }
        }
      }

      .hs-fieldtype-file {

      }
      

      

    }

    input[type="submit"] {
          position: relative;
          z-index: 1;
          display: inline-flex;
          justify-content: center;
          padding: 0;
          color: ${variables.colors.white};
          background: ${variables.colors.red};
          font-family: 'Bossa', sans-serif;
          font-weight: 700;
          text-decoration: none;
          cursor: pointer;
          overflow: hidden;
          box-shadow: 0px 9px 90px rgba(7, 13, 28, 0.25);
          border: 0;

          ${fs(16)}

          ${screenSize('height', 62)}
          ${screenSize('line-height', 60)}
          ${screenSize('border-radius', 62)}
          ${screenSize('padding-left', 35)}
          ${screenSize('padding-right', 35)}

          transition: all .3s ease-in;

          &:hover {
            background: ${variables.colors.blue};
          }
        }

  .hs-error-msgs {
        list-style-type: none;
        padding: 5px 0 0;
        margin: 0;
        label.hs-error-msg {
          ${fs(14)}
          color: ${variables.colors.red};
        }
      }
  }
  
  .submitted-message {
    
    p {
      display: flex;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      color: ${variables.colors.backgroundDark};

      ${fs(18)}
      &::before {
        content: '';
        display: inline-block;
        width: 36px;
        height: 36px;
        background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 0.125C8.14383 0.125 0.125 8.14383 0.125 18C0.125 27.8562 8.14383 35.875 18 35.875C27.8562 35.875 35.875 27.8562 35.875 18C35.875 8.14383 27.8562 0.125 18 0.125ZM27.3027 12.0093L15.7527 25.7593C15.626 25.9102 15.4684 26.0321 15.2905 26.1168C15.1125 26.2014 14.9185 26.2469 14.7215 26.25H14.6983C14.5056 26.2499 14.315 26.2093 14.139 26.1309C13.9629 26.0524 13.8054 25.9378 13.6765 25.7945L8.72648 20.2945C8.60077 20.1612 8.50298 20.0041 8.43886 19.8324C8.37473 19.6607 8.34558 19.478 8.35309 19.2949C8.36061 19.1118 8.40465 18.932 8.48262 18.7662C8.5606 18.6004 8.67094 18.4518 8.80716 18.3292C8.94337 18.2066 9.10272 18.1125 9.27583 18.0524C9.44894 17.9923 9.63232 17.9674 9.8152 17.9791C9.99807 17.9909 10.1768 18.0391 10.3407 18.1209C10.5047 18.2027 10.6507 18.3164 10.7701 18.4555L14.6622 22.7798L25.1973 10.2407C25.4336 9.96749 25.7679 9.79824 26.128 9.76955C26.4881 9.74086 26.845 9.85502 27.1216 10.0874C27.3982 10.3197 27.5723 10.6516 27.6062 11.0112C27.6401 11.3708 27.5311 11.7294 27.3027 12.0093Z' fill='%2354E9DC'/%3E%3C/svg%3E%0A");
        background-size: 100% auto;
        margin-right: 14px;
      }
    }
    
  }

  .submitted-message {
    
    p {
      display: flex;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      color: ${variables.colors.backgroundDark};

      ${fs(18)}
      &::before {
        content: '';
        display: inline-block;
        width: 36px;
        height: 36px;
        background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 0.125C8.14383 0.125 0.125 8.14383 0.125 18C0.125 27.8562 8.14383 35.875 18 35.875C27.8562 35.875 35.875 27.8562 35.875 18C35.875 8.14383 27.8562 0.125 18 0.125ZM27.3027 12.0093L15.7527 25.7593C15.626 25.9102 15.4684 26.0321 15.2905 26.1168C15.1125 26.2014 14.9185 26.2469 14.7215 26.25H14.6983C14.5056 26.2499 14.315 26.2093 14.139 26.1309C13.9629 26.0524 13.8054 25.9378 13.6765 25.7945L8.72648 20.2945C8.60077 20.1612 8.50298 20.0041 8.43886 19.8324C8.37473 19.6607 8.34558 19.478 8.35309 19.2949C8.36061 19.1118 8.40465 18.932 8.48262 18.7662C8.5606 18.6004 8.67094 18.4518 8.80716 18.3292C8.94337 18.2066 9.10272 18.1125 9.27583 18.0524C9.44894 17.9923 9.63232 17.9674 9.8152 17.9791C9.99807 17.9909 10.1768 18.0391 10.3407 18.1209C10.5047 18.2027 10.6507 18.3164 10.7701 18.4555L14.6622 22.7798L25.1973 10.2407C25.4336 9.96749 25.7679 9.79824 26.128 9.76955C26.4881 9.74086 26.845 9.85502 27.1216 10.0874C27.3982 10.3197 27.5723 10.6516 27.6062 11.0112C27.6401 11.3708 27.5311 11.7294 27.3027 12.0093Z' fill='%2354E9DC'/%3E%3C/svg%3E%0A");
        background-size: 100% auto;
        margin-right: 14px;
      }
    }
    
  }

`;