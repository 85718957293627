import React, { useState, createRef, useEffect } from 'react';
import Image from "../../components/image"

import { Container, Content, Filters, Cards, Card } from './styled.js';

import {Button} from '../common/button';

const CommunityGallery = (props) => {

  const showedStep = 6;

  const [currentTag, setCurrentTag] = useState(null);
  const [maxShowed, setMaxShowed] = useState(showedStep);
  const [listToShow, setListToShow] = useState([]);
  const [totalToShow, setTotalToShow] = useState([]);
  const [mobileFiltersMenuOpened, setMobileFiltersMenuOpened] = useState(false);

  useEffect(() => {
    selectDataToShow();
  }, [maxShowed, currentTag]);

  const selectDataToShow = () => {
    let output = [];
    let totalToShow = 0;
    props.cards.map((item) => {
      const itemData = item.node.data;
      if(currentTag != null && !itemData.Tags.includes(currentTag)) {
        // not to show (tag)
      } else if(itemData === props.featured) {
        // featured item
      } else {
        totalToShow++;
        if (totalToShow <= maxShowed) {
          output.push(itemData);
        }
      }
    });
    setListToShow(output);
    setTotalToShow(totalToShow);
  }

  return (
    <Container>
      <Content>
        <span className="baseline">The gallery</span>
        <h2>Find the perfect design for your presentation</h2>
        <p>We love creatives. And we know they can produce amazing things. Inspiration, templates, make your pick. </p>
      </Content>
      {(props.tags) ?
      (<Filters>
        <div className="filters">
          <div className={`filters-menu ${(mobileFiltersMenuOpened) ? 'filters-menu-opened' : ''}`}>
            <span className="label">Filter by:</span>
            <ul>
              {props.tags.map((tag, index) => { return (
                <li key={`tag-trigger-${index}`}>
                  <button className={(tag === currentTag) ? 'active' : ''} onClick={() => {
                    if(tag !== currentTag) {
                      setCurrentTag(tag);
                    } else {
                      setCurrentTag(null);
                    }
                    setMaxShowed(showedStep);
                  }}>
                    {tag}
                  </button>
                </li>
              )})}
            </ul>
          </div>
          <button 
            className="filters-menu-trigger-mobile"
            onClick={() => {
              setMobileFiltersMenuOpened(!mobileFiltersMenuOpened);
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.125 7.875H1.875C1.57663 7.875 1.29048 7.75647 1.0795 7.5455C0.868526 7.33452 0.75 7.04837 0.75 6.75C0.75 6.45163 0.868526 6.16548 1.0795 5.9545C1.29048 5.74353 1.57663 5.625 1.875 5.625H22.125C22.4234 5.625 22.7095 5.74353 22.9205 5.9545C23.1315 6.16548 23.25 6.45163 23.25 6.75C23.25 7.04837 23.1315 7.33452 22.9205 7.5455C22.7095 7.75647 22.4234 7.875 22.125 7.875Z" fill="#FF6562"/>
              <path d="M18.375 13.125H5.625C5.32663 13.125 5.04048 13.0065 4.8295 12.7955C4.61853 12.5845 4.5 12.2984 4.5 12C4.5 11.7016 4.61853 11.4155 4.8295 11.2045C5.04048 10.9935 5.32663 10.875 5.625 10.875H18.375C18.6734 10.875 18.9595 10.9935 19.1705 11.2045C19.3815 11.4155 19.5 11.7016 19.5 12C19.5 12.2984 19.3815 12.5845 19.1705 12.7955C18.9595 13.0065 18.6734 13.125 18.375 13.125Z" fill="#FF6562"/>
              <path d="M13.875 18.375H10.125C9.82663 18.375 9.54048 18.2565 9.3295 18.0455C9.11853 17.8345 9 17.5484 9 17.25C9 16.9516 9.11853 16.6655 9.3295 16.4545C9.54048 16.2435 9.82663 16.125 10.125 16.125H13.875C14.1734 16.125 14.4595 16.2435 14.6705 16.4545C14.8815 16.6655 15 16.9516 15 17.25C15 17.5484 14.8815 17.8345 14.6705 18.0455C14.4595 18.2565 14.1734 18.375 13.875 18.375Z" fill="#FF6562"/>
            </svg>
          </button>
        </div>
        <div className="actions">
          <Button href="/submit-your-presentation">Submit yours</Button>
        </div>
      </Filters>) : null}
      <Cards>
        
        {listToShow.map((item, index) => {
          return (
            <Card key={`card-slide-${index}`}>
              <a className="thumbnail" href={item.Url} target="_blank">
                <span className="inner"><img src={`/gallery-posters/${item.PictureName}`} /></span>
              </a>

              <h3 className="title">{item.Title}</h3>
              <ul className="tags">
                {item.Tags.map((tag, indexTag) => (<li key={`tag-list-${indexTag}`}>{tag}</li>) )}
              </ul>
              
              <div className="description">
                {(item.Description !== null && item.Description !== ' ' && item.Description !== '-') ? (
                  <p>{item.Description}</p>
                ) : null}
              </div>

              <div className="footer">
                {item.AuthorWebsite && item.AuthorWebsite[0] ? (
                  <a className="author" href={item.AuthorWebsite[0]} target="_blank">
                    <span className="author-avatar">
                      {item.AuthorPictureName ? 
                        <img src={`/gallery-avatars/${item.AuthorPictureName}`} />
                      : 
                        <Image filename="photo_placeholder.png" />
                      }
                    </span>
                    <span className="author-name">
                      {item.AuthorName && item.AuthorName[0] ? item.AuthorName[0] : ''}
                    </span>
                  </a>
                ) : (
                  <span className="author">
                    <span className="author-avatar">
                      {item.AuthorPictureName ? 
                        <img src={`/gallery-avatars/${item.AuthorPictureName}`} />
                      : 
                        <Image filename="photo_placeholder.png" />
                      }
                    </span>
                    <span className="author-name">
                      {item.AuthorName && item.AuthorName[0] ? item.AuthorName[0] : ''}
                    </span>
                  </span>
                )}
                <div className="action">
                  {item.CloneUrl ? (
                    <Button href={item.CloneUrl} className="ghost" target="_blank">Clone in Ludus</Button>
                  ) : item.Url ? <Button href={item.Url} className="ghost" target="_blank">View in Ludus</Button> : null}
                  {/* {item.Url ? <Button href={item.Url} className="ghost" target="_blank">View in Ludus</Button> : null} */}
                </div>
              </div>
              
            </Card>
          )
        })}
      </Cards>
      {(listToShow.length < totalToShow) ? (
        <div className="loadmore-wrapper">
          <Button className="ghost large" onClick={() => {
            setMaxShowed(maxShowed + showedStep);
          }}>
            Load More
            {/* ({listToShow.length}/{totalToShow}) */}
          </Button>
        </div>
      ) : null}
      
    </Container>
  )
}

export default CommunityGallery;
