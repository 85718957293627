import React, { useState, createRef, useEffect } from "react"

import { Container, Content } from "./styled.js"

const ContactForm = props => {
  let contactFormEl = createRef()

  const [contactFormAdded, setContactFormAdded] = useState(false)

  useEffect(() => {
    if (!contactFormAdded && window && contactFormEl.current) {
      addScript(contactFormEl.current)
    }
  }, [])

  const addScript = el => {
    const scriptExternal = document.createElement("script")
    scriptExternal.src = "//js.hsforms.net/forms/v2.js"
    el.appendChild(scriptExternal)

    if (window.hbspt && el) {
      addContactForm(el)
    } else {
      setTimeout(() => {
        addScript(el)
      }, 200)
    }
  }

  const addContactForm = el => {
    setTimeout(() => {
      const scriptCode = document.createElement("script")
      scriptCode.innerHTML = `
      hbspt.forms.create({
        region: "na1",
        portalId: "7066418",
        formId: "e27feb23-18f8-4238-b510-048105a15fa8"
      });
      `
      el.appendChild(scriptCode)
      setContactFormAdded(true)
    }, 0)
  }

  return (
    <Container>
      <h2>How does it work?</h2>
      <Content>
        <div className="steps">
          <ol>
            <li>
              1<strong>Have a Ludus account</strong>
            </li>
            <li>
              2<strong>Fill the form</strong>
            </li>
            <li>
              3<strong>Wait for validation</strong>
            </li>
            <li>
              4<strong>Inspire others!</strong>
            </li>
          </ol>
        </div>
        <div className="form-wrapper">
          <div className="header">
            <h3>Share a presentation</h3>
            <p>
              If you want to showcase your work, please fill out this part. By
              the way, did you know that if you make your presentation clonable,
              users can learn better by working right into it.
            </p>
          </div>
          <h3>Your info</h3>
          <div className="form-container" ref={contactFormEl} />
        </div>
      </Content>
    </Container>
  )
}

export default ContactForm
