import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import bodymovin from "lottie-web"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from "../components/common/section"
import ContentHero from "../components/content-hero"
import CommunityGallery from "../components/community-gallery"

import CommunityForm from "../components/community-form"

export const query = graphql`
  query PostQuery {
    allAirtable(
      filter: { table: { eq: "Gallery" }, data: {} }
      sort: { fields: [data___Order], order: ASC }
    ) {
      edges {
        node {
          data {
            Order
            Title
            Featured
            Url
            Screenshot {
              url
            }
            PictureName
            Tags
            Description
            AuthorName
            AuthorWebsite
            AuthorAvatar {
              url
            }
            AuthorPictureName
            CloneUrl
          }
        }
      }
    }
  }
`

const CommunityFormPage = ({ data }) => {
  let animationBackground = useRef()

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  useEffect(() => {
    if (
      animationBackground &&
      animationBackground.current &&
      !animationBackground.current.loaded
    ) {
      animationBackground.current.loaded = true
      bodymovin.loadAnimation({
        useSubFrames: false,
        wrapper: animationBackground.current,
        animType: "svg",
        autoplay: true,
        loop: false,
        path: "/animations/Content-shape-left.json",
      })
    }
  }, [currentBreakPoint])

  return (
    <Layout
      socialDark={true}
      currentPage="community"
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Ludus Community" />

      <Section>
        <div
          style={{
            display: currentBreakPoint.type === "mobile" ? "none" : "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? "58%" : "26%",
            height: "39%",
            top: currentBreakPoint.type === "mobile" ? "250px" : "190px",
            right: "0",
          }}
        >
          <div>
            <div
              className="animationBackground"
              ref={animationBackground}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>

        <div
          style={{
            display: currentBreakPoint.type === "mobile" ? "none" : "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? "58%" : "26%",
            height: "39%",
            top: currentBreakPoint.type === "mobile" ? "250px" : "190px",
            right: "0",
          }}
        >
          <div>
            <div
              className="animationBackground"
              ref={animationBackground}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
        <ContentHero
          title="Submit your presentation<br />to the Ludus gallery"
          promotext="<strong>... and get 3 months of Ludus for free</strong>if your presentation makes it to the Ludus gallery"
          subtext="We love creatives. And we know they can produce amazing things. Inspiration, templates, make your pick."
        />
      </Section>

      <Section>
        <CommunityForm />
      </Section>

      <Section light={true}>
        <CommunityGallery cards={data.allAirtable.edges} />
      </Section>
    </Layout>
  )
}

export default CommunityFormPage
