import styled from 'styled-components';
import variables, { mq, fs, screenSize } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 150px 5vw 0;

  ${mq('l')} {
    min-height: 'none';
    padding: 45px 35px ${props => props.styleType === 'pricing' ? '20vh' : '50px'};
  }

  ${mq('s')} {
    padding: 45px 20px 50px;
  }


  .loadmore-wrapper {
    padding: 10px 10px 65px;

    > a {
      border: 2px solid ${variables.colors.red};
      box-shadow: none;

      &:hover {
        border-color: ${variables.colors.blue};
      }
    }
  }
`;

export const Content = styled.div`
  width: 50%;
  max-width: 600px;
  text-align: center;
  margin-bottom: 30px;

  ${mq("m")} {
    width: 100%;
  }

  .baseline {
    display: block;
    color: ${variables.colors.blue};
    ${fs(18)};
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
  }

  h2 {
    color: ${variables.colors.backgroundDark};
    ${fs(36)};
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
  }

  p {
    color: ${variables.colors.greyDarker};
    ${fs(18)};
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.7;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;

  ${mq("m")} {
    position: relative;
  }

  ${screenSize('margin-bottom', 40)};

  .filters {
    ${mq("m")} {
      /* position: relative; */
    }
    .label {
      display: block;
      margin-bottom: 12px;
      font-family: 'Bossa', sans-serif;
      font-weight: 500;
      color: ${variables.colors.greyDarker};
      ${fs(16)};
    }
    ul {
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      flex: 1;
      flex-wrap: wrap;
      margin-right: 100px;

      ${mq("m")} {
        flex-direction: column;
        margin-right: 0;
      }

      li {
        padding-right: 12px;

        button {
          font-family: 'Bossa', sans-serif;
          font-weight: 700;
          color: ${variables.colors.blue};
          border: 2px solid ${variables.colors.blue};
          background: ${variables.colors.white};
          transition: all .3s ease-in-out;
          cursor: pointer;
          ${fs(16)};
          ${screenSize('border-radius', 24)};
          ${screenSize('padding-top', 10)};
          ${screenSize('padding-bottom', 10)};
          ${screenSize('padding-left', 30)};
          ${screenSize('padding-right', 30)};

          &:hover,
          &.active {
            background: ${variables.colors.blue};
            color: ${variables.colors.white};
          }

          ${mq("m")} {
            width: 100%;
          }
        }
      }
    }

    .filters-menu {

      ${mq("m")} {
        position: absolute;
        top: calc(100% + 10px);
        width: 100%;
        background: ${variables.colors.white};
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0px 2.4451px 20.7834px rgba(0, 0, 0, 0.18);
        opacity: 0;
        pointer-events: none;
        transition: all .3s ease-in-out;
        z-index: 1500;
      }

      &-opened {
        ${mq("m")} {
          opacity: 1;
          pointer-events: all;

          + button {
            transition: all .3s ease-in-out;
            border-color: ${variables.colors.blue} !important;
            svg path {
              transition: all .3s ease-in-out;
              fill: ${variables.colors.blue} !important;
            }
          }
        }
      }

      &-trigger-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: 1px solid ${variables.colors.red};
        border-radius: 50%;
        margin-right: 30px;

        ${mq("m", true)} {
          display: none;
        }
      }

    }
    
  }

  .actions {
    > a {
      white-space: nowrap;
      filter: drop-shadow(-1px 9px 20px rgba(116, 116, 188, 0.25));
    }
    ${mq("m")} {
      width: 100%;

      > a {
        width: 100%;
      }
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin: 0 -25px;

  ${mq("xxl")} {
    margin: 0 -15px;
  }

  ${mq("m")} {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 50px);
  align-items: center;
  margin: 0 25px;

  ${mq("xxl")} {
    width: calc(100% / 2 - 30px);
    margin: 0 15px;
  }

  ${mq("m")} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }


  ${screenSize('margin-bottom', 60)};

  .thumbnail {
    
    width: 100%;
    background: ${variables.colors.greyLighter};
    margin-bottom: 20px;
    /* box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.18); */
    ${screenSize('padding', 15)};
    ${screenSize('border-radius', 24)};

    ${mq('xl')} {
      ${screenSize('padding', 10)};
      ${screenSize('border-radius', 16)};
    }

    .inner {
      position: relative;
      width: 100%;
      display: block;

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 54%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        margin: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        ${screenSize('border-radius', 15)};
        ${mq('xl')} {
          ${screenSize('border-radius', 10)};
        }
      }
    }
  }

  .description {
    flex: 1;

    p {
      margin-bottom: 30px;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      line-height: 1.5;
      color: ${variables.colors.greyDarker};
      ${fs(16)};
    }
  }

  .tags {
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin-left: 0;


    li {
      display: block;
      color: ${variables.colors.blue};
      ${fs(16)};
      font-family: 'Bossa', sans-serif;
      font-weight: 500;
      margin-bottom: 20px;

      + li {
        margin-left: 10px;

        &::before {
          content: '•';
          display: inline;
          margin-right: 8px;
        }
      }
    }
  }

  h3 {
    display: block;
    color: ${variables.colors.backgroundDark};
    ${fs(24)};
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
    ${screenSize('line-height', 31)};
  }

  .author {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  

  .author-name {
    display: block;
    color: ${variables.colors.backgroundDark};
    ${fs(16)};
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-decoration: none;
    transition: color .3s ease-in-out;
    flex: 1;

    ${mq("s")} {
      // display: none;
    }
  }

  .author-avatar {
    display: inline-block;
    margin-right: 10px;

    > div,
    img {
      display: inline-block;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      overflow:hidden;
      object-fit: cover;
      margin-bottom: 0;
      border: 1px solid ${variables.colors.greyLighter};
    }
  }

  a.author {
    ${mq("s")} {
      margin-bottom: 20px;
    }
    &:hover {
      .author-name {
        color: ${variables.colors.red};
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${mq("s")} {
      flex-direction: column;
      align-items: center;

      .action {
        
      }
    }

    .action a {
      white-space: nowrap;
      ${fs(14)};
      ${screenSize('height', 49)}
      ${screenSize('line-height', 49)}
    }
  }
`;
